import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const ServiceInfo = (props) => {
  return (
    <div className="container">
      <div className="row">
        <div className="col-6">
          <p>SRC: {props.sameResponseCount}</p>
          <p>PC: {props.pollCount}</p>
          <p>SS: {props.ServiceStatus}</p>
        </div>
        <div className="col-12">
          <span>Id: {props.serviceId} </span>
          <span className="pull-right service_recently_configured">
            {props.serviceRecentlyConfigured === false ? (
              <FontAwesomeIcon icon={regular("check-circle")} size='lg' pull="right" style={{color: "green"}}/>
            ) : (props.serviceRecentlyConfigured === true ?
              <FontAwesomeIcon icon={solid("spinner")} size='lg' spin pull="right"/> : null
            )}
          </span>
        </div>
      </div>
    </div>
  );
};

export default ServiceInfo;
