const AuthTypes = {
    EMAIL_PASSWORD: 0,
    GOOGLE_AUTH: 1
}

class User{
    constructor(name = '', surname = '', username = '', authType = AuthTypes.EMAIL_PASSWORD){
        this.name = name;
        this.surname = surname;
        this.username = username;
        this.authType = authType
    }
}

export {User, AuthTypes};