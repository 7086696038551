import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import useGoogleAuth from "../../hooks/useGoogleAuth";

const GoogleAuth = () => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [isSignInWithGoogle, googleApi] = useGoogleAuth();

  async function onSignIn() {
    await googleApi.signIn();
    navigate('/');
  }

  return (
    <button onClick={onSignIn} className="btn btn-primary m-2">
      <i className="google icon" />
      {t('sign_in_with_google')}
    </button>
  );
};

export default GoogleAuth;
