import GoogleAuth from '../../components/google-auth/GoogleAuth.component';
import SignInWithMail from '../../components/sign-in-with-mail/SignInWithMail.component';

const Login = () => {
    return(
        <>
            <SignInWithMail />
            <GoogleAuth />
        </>
    )
}

export default Login