import styled, { createGlobalStyle } from "styled-components";

const GlobalStyle = createGlobalStyle`
    *{
        box-sizing: border-box;
        margin: 0;
        padding: 0;
    }
`;

export const Button = styled.button`
padding: 5px 7px;
color: #fff;
background-color: #3c8dbc;
border: 1px solid #3c8dbc;
`;

export default GlobalStyle;