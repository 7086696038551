import { createContext, useEffect, useState } from "react";

const AuthContext = createContext({});

export const AuthProvider = ({ children }) => {
  const [auth, setAuth] = useState();

  useEffect(() => {
    let user = JSON.parse(localStorage.getItem('jwt'));
    if (user)
      setAuth(user);
  }, [])

  useEffect(() => {
    if (!auth)
      localStorage.removeItem('jwt');
    else
      localStorage.setItem('jwt', JSON.stringify(auth))
  }, [auth])

  return (
    <AuthContext.Provider value={{ auth, setAuth }}>
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
