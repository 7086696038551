import React, { Suspense } from 'react';
import ReactDOM from 'react-dom/client';
import App from './pages/App';
import LoadingSpinner from './commons/loader/loader.component';
import './utils/localization';
import { BrowserRouter, Routes, Route } from 'react-router-dom';

import 'flag-icon-css/css/flag-icons.min.css'
import 'bootstrap/dist/js/bootstrap.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import { AuthProvider } from './contexts/AuthProvider';

const loadingMarkup = (
    <div className='py-4 text-center'>
        <LoadingSpinner />
    </div>
)

const el = document.getElementById('root');
const root = ReactDOM.createRoot(el);

root.render(
    <Suspense fallback={loadingMarkup}>
        <React.StrictMode>
            <BrowserRouter>
                <AuthProvider>
                    <Routes>
                        <Route path="/*" element={<App />}></Route>
                    </Routes>
                </AuthProvider>
            </BrowserRouter>
        </React.StrictMode>
    </Suspense>
)