import Joi, { validate } from "joi-browser";
import { useTranslation } from "react-i18next";

function useLoginValidations() {
  const { t } = useTranslation();

  const joiSchema = Joi.object().keys({
    username: Joi.string()
      .required()
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = t("required");
              break;
            default:
              break;
          }
        });
        return errors;
      }),
    password: Joi.string()
      .required()
      .min(8)
      .error((errors) => {
        errors.forEach((err) => {
          switch (err.type) {
            case "any.empty":
              err.message = t("required");;
              break;
            case "string.min":
              err.message = t("min_string_length", {
                value: err.context.limit,
              });
              break;
            default:
              break;
          }
        });
        return errors;
      }),
  });

  function valid(obj) {
    const { error } = validate(obj, joiSchema, { abortEarly: false });
    if (!error) return null;
    const errors = {};
    for (let item of error.details) errors[item.path[0]] = item.message;
    return errors;
  }

  return [valid];
}
export default useLoginValidations;
