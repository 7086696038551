import React from "react";
import useGoogleAuth from "../../hooks/useGoogleAuth";
import { useTranslation } from 'react-i18next';
import useAuth from "../../hooks/useAuth";
import { AuthTypes } from "../../models/user";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const LogOut = () => {
  const { auth, setAuth } = useAuth();
  const [isSignInWithGoogle, googleApi] = useGoogleAuth();
  const { t } = useTranslation();
  const navigate = useNavigate();

  function signOut() {
    setAuth(null);
  }

  function onSignOut() {
    if (auth.authType === AuthTypes.GOOGLE_AUTH)
      googleApi.signOut();
    else
      signOut();
    navigate("/login")
  }

  return (
    <button onClick={onSignOut} className="logout-btn">
      {t("sign_out")}
      <FontAwesomeIcon className="m-l-10" icon={solid("sign-out")} size='lg' />
    </button>
  );
};

export default LogOut;