import { useEffect, useState } from "react";
import { AuthTypes, User } from "../models/user";
import useAuth from "./useAuth";

function useGoogleAuth() {
  const { setAuth } = useAuth();
  const [isSignInWithGoogle, setIsSignInWithGoogle] = useState(false)
  const [googleApi, setGoogleApi] = useState();

  function onAuthChange() {
    if (window.gapi.auth2.getAuthInstance().isSignedIn.get()) {
      let username = window.gapi.auth2.getAuthInstance().currentUser.get().getBasicProfile()?.getName();
      let loggingUser = new User('', '', username, AuthTypes.GOOGLE_AUTH);
      setAuth({ ...loggingUser });
      setIsSignInWithGoogle(true);
    }
    else {
      setAuth(null);
    }
  }

  useEffect(() => {
    window.gapi.load("client:auth2", () => {
      window.gapi.client
        .init({
          clientId:
            "375916919822-d49r1abpoiti46dkfvf9fca2ubbo5kac.apps.googleusercontent.com",
          scope: "email",
          plugin_name: "lds-aui",
        })
        .then(() => {
          var authInstance = window.gapi.auth2.getAuthInstance();
          authInstance.currentUser.listen(onAuthChange);
          setGoogleApi(authInstance);
        });
    });
  }, []);
  return [isSignInWithGoogle, googleApi];
}

export default useGoogleAuth