import { useEffect } from 'react';
import i18next from 'i18next'
import cookies from 'js-cookie'
import languages from '../../utils/localization';
import { useTranslation } from 'react-i18next';

const LanguageSelect = () => {
    const currentLanguageCode = cookies.get('i18next') || 'sr';
    const currentLanguage = languages.find(x => x.code === currentLanguageCode);
    const { t } = useTranslation();

    useEffect(() => {
        document.title = t('app_title')
    }, [currentLanguage, t])

    return (
        <div className="language-select">
            <div className="d-flex justify-content-end align-items-center language-select-root">
                <div className="dropdown">
                    <button
                        className="btn btn-link"
                        type="button"
                        id="dropdownMenuButton1"
                        data-bs-toggle="dropdown"
                        aria-expanded="false"
                    >
                        <span
                            className={`flag-icon flag-icon-${currentLanguage.country_code} mx-2`}
                        ></span>
                    </button>
                    <ul className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                        <li>
                            <span className="dropdown-item-text">{t('language')}</span>
                        </li>
                        {languages.map(({ code, name, country_code }) => (
                            <li key={code}>
                                <button
                                    className='dropdown-item'
                                    disabled={currentLanguageCode === code}
                                    onClick={() => {
                                        i18next.changeLanguage(code)
                                    }}
                                >
                                    <span
                                        className={`flag-icon flag-icon-${country_code} mx-2`}
                                        style={{
                                            opacity: currentLanguageCode === code ? 0.5 : 1,
                                        }}
                                    ></span>
                                    {name}
                                </button>
                            </li>
                        ))}
                    </ul>
                </div>
            </div>
        </div>
    )
}

export default LanguageSelect;