import React from "react";
import AllServices from "./all-services/AllServices";
import Layout from "../components/layout/Layout.component";
import Login from "./login/Login";
import { Routes, Route } from "react-router-dom";
import "../styles/App.css";
import ProtectedRoute from "../validations/protectedRoute";
import PublicRoute from "../validations/publicRoute";
import GlobalStyle from "../styles/globalStyles";

const App = () => {
  return (
      <div className="container App">
        <GlobalStyle />
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route element={<PublicRoute />}>
              <Route path="login" element={<Login />} />
            </Route>
            <Route element={<ProtectedRoute />}>
              <Route path="/" element={<AllServices />} />
            </Route>
          </Route>
        </Routes>
      </div>
  );
};

export default App;
