import { Outlet } from "react-router-dom";
import NavBar from "../../commons/navbar/navbar.component";
import Footer from "../../commons/footer/Footer.component";

const Layout = () => {
  return (
    <>
      <main className="App">
        <NavBar></NavBar>
        <Outlet />
      </main>
    </>
  );
};

export default Layout;
