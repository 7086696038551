import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import useAuth from "../../hooks/useAuth";
import { useTranslation } from "react-i18next";
import useLoginValidations from '../../validations/useLoginValidations'
import { AuthTypes, User } from "../../models/user";
import { Card } from "react-bootstrap";

const SignInWithMail = () => {
  const [user, setUser] = useState("");
  const [password, setPassword] = useState("");
  const [errors, setErrors] = useState(null);
  const { setAuth } = useAuth();
  const [valid] = useLoginValidations();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const handleSubmit = (e) => {
    e.preventDefault();
    const obj = { username: user, password: password };
    setErrors(valid(obj));
    if (!errors) {
      let loggingUser = new User('', '', user, AuthTypes.EMAIL_PASSWORD);
      setAuth({ ...loggingUser });
      navigate("/")
    };
  };

  return (
    <Card>
      <Card.Body>
        <Card.Title>
          {t("sign_in")}
        </Card.Title>
        <Card.Text>
          <form onSubmit={handleSubmit}>
            <div className="container">
              <div className="form-group">
                <label htmlFor="username">{t("username")}</label>
                <input
                  type="text"
                  className="form-control"
                  id="username"
                  autoComplete="off"
                  value={user}
                  onChange={(event) => setUser(event.target.value)}
                />
                <span className="text-danger"> {errors?.username} </span>
              </div>

              <div className="form-group">
                <label htmlFor="password">{t("password")}</label>
                <input
                  type="password"
                  className="form-control"
                  id="password"
                  value={password}
                  onChange={(event) => setPassword(event.target.value)}
                />
                <span className="text-danger"> {errors?.password} </span>
              </div>

              <hr></hr>

              <button className="btn btn-success btn-lg col-12">{t("sign_in")}</button>
            </div>
          </form>
        </Card.Text>
      </Card.Body>
    </Card>
  );
};

export default SignInWithMail;
