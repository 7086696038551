import api from "../../api";
import { env } from "../../env";

const baseUrl = env.REACT_APP_API_URL;

class OrchestratorClient {
    async PingAll() {
        var res = await api.get(baseUrl + 'Orchestrator/GetPollingInfo')
        return res;
    }

    async StartConfigurationRunnerAgain() {
        var res = await api.get(baseUrl + 'Orchestrator/StartConfigurationRunnerAgain')
        return res;
    }

    async StopConfigurationRunnerAndAllServices() {
        var res = await api.get(baseUrl + 'Orchestrator/StopConfigurationRunnerAndAllServices')
        return res;
    }

    async StopSpecificService(serviceId) {
        var res = await api.get(baseUrl + 'Orchestrator/StopSpecificService?serviceId='+serviceId)
        return res;
    }

    
    async StartSpecificService(serviceId) {
        var res = await api.get(baseUrl + 'Orchestrator/StartSpecificService?serviceId='+serviceId)
        return res;
    }
}

export default OrchestratorClient;