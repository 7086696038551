import React, { useState, useEffect } from "react";
import './Topics.style.css'

const Topics = (props) => {
  const [ListeningTopic, SetListeningTopics] = useState("");
  const [ProducingTopic, SetProducingTopic] = useState("");

  useEffect(() => {
    SetListeningTopics(props.ServiceTaskInfo.Listening);
    SetProducingTopic(props.ServiceTaskInfo.Producing);
  }, []);

  return (
    <div>
      <div className="container topic">
        <div className="row">
          <div className="col-5">
            {/* {ListeningTopics?.map((item, index) => (
              <div key={index}>{item}</div>
            ))} */
            ListeningTopic}
          </div>
          <div className="col-2"> {"->"} </div>
          <div className="col-5">{ProducingTopic}</div>
        </div>
      </div>
    </div>
  );
};

export default Topics;
