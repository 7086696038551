import Container from "react-bootstrap/Container";
import Navbar from "react-bootstrap/Navbar";
import Nav from "react-bootstrap/Nav";
import { useTranslation } from "react-i18next";
import { Link } from "react-router-dom";
import LanguageSelect from "../../components/language-select/language-select.component";
import useAuth from "../../hooks/useAuth";
import LogOut from "../../components/log-out/LogOut.component";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { regular, solid } from "@fortawesome/fontawesome-svg-core/import.macro"

const NavBar = () => {
  const { t } = useTranslation();
  const { auth } = useAuth();

  return (
    <Navbar id="navBar" bg="light" variant="light" fixed="top">
      <Container>
        <Link to={"/"} className="navbar-brand">{t("app_title")}</Link>
        <div className="navigation-user-holder">
        <LanguageSelect />
        <FontAwesomeIcon className="user-icon" icon={solid("user")} size='lg' />
        <span className="nav-bar-text">{auth?.username}</span>
        <Nav className="me-auto">
          {auth ? (
            <LogOut />
          ) : (
            <Link className="nav-bar-text" to="/login">
              {t("log_in")}
            </Link>
          )}
        </Nav>
        </div>
      </Container>
    </Navbar>
  );
};

export default NavBar;
